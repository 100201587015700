import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import { Box, 
    Flex, 
    Text, 
    Button, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    useToast, 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon,
    HStack} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useAuth } from "../Components/auth/AuthProvider";
import CustomSpinner from '../Components/CustomSpinner';
import axios from 'axios';
import { format } from 'date-fns';

function Account() {
    const toast = useToast();
    const { logOut } = useAuth();
    const [userData, setUserData] = useState(null);
    const { user, token } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [modalPhoneNumber, setModalPhoneNumber] = useState('');
    const [modalEmail, setModalEmail] = useState('');
    const [universityInfo, setUniversityInfo] = useState({});

    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    const logout = () => {
        logOut();
    }

    useEffect(() => {
        fetchUserData();

        getContractDetails();
    }, [user]);

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/applications/${user}`, config);
            setUserData(response.data);
            setPhoneNumber(response.data.phone_number); // Set initial phone number
            setEmail(response.data.email); // Set initial email
            setModalPhoneNumber(response.data.phone_number);
            setModalEmail(response.data.email);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const getContractDetails = async () => {
        axios.get(`${baseUrl}/applications/${user}`, config)
        .then((response) => {
          const universityId = response.data.university.id;

          axios.get(`${baseUrl}/universities/`, config)
          .then((response) => {
              //  universityId stores as 1 based index, so subtract 1
              setUniversityInfo(response.data[universityId - 1])
          })
          .catch(error => {
              console.error('Error:', error);
          });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const handleSaveChanges = async () => {
        console.log('Saving changes:', { modalPhoneNumber, modalEmail });
        
        const data = {
            phone_number: modalPhoneNumber,
            email: modalEmail,
        };

        axios
        .put(
            `${baseUrl}/applications/${user}`, data, config
        )
        .then((response) => {
            toast({
            title: "Basic Information successfully edited",
            position: "top",
            status: "success",
            duration: 5000,
            isClosable: true,
            });
            setUserData(response.data);
            setPhoneNumber(response.data.phone_number);
            setEmail(response.data.email);
            setModalPhoneNumber(response.data.phone_number);
            setModalEmail(response.data.email);
            onClose();
        })
        .catch((err) => {
            onClose();
            toast({
            title: "Failed to edit application",
            position: "top",
            status: "error",
            duration: 5000,
            isClosable: true,
            });
            console.error("Error editing application: ", err);
        });
    };

    const contractInfo = `
        <br>
        <strong>Agreement</strong>
        <br>
        This is an agreement between the technology company known as “Jump Finance”, the educational institution known as ${universityInfo.name}, and the student borrower, hereto forward referred to as the “student”.
        <br><br>
        <strong>Description of Framework</strong>
        <br>
        As a result of a successful credit screening from Jump Finance, ${universityInfo.name} has enrolled the student in the Spring 2022 semester. As a condition of receiving this enrollment, the student agrees to pay for this tuition in future monthly installments. These repayments will continue until the tuition advance is paid in full. A detailed breakdown of the repayments is below.
        <br><br>
        <strong>Term of Repayment and Penalties for Default</strong>
        <br>
        The repayment structure and schedule can only be amended through a written agreement between the student, ${universityInfo.name}, and Jump Finance. If the student defaults during school (meaning s/he does not make a payment of any kind for more than 30 days past the due date), ${universityInfo.name} withholds the right to block enrollment, and if student defaults after graduating, ${universityInfo.name} withholds the right to nullify earned credentials. If the student receives a Ministry of Higher Education (MOHE) scholarship (or any other type of scholarship) that pays off their past and future ${universityInfo.name} tuition payments, outside of their current membership fees the student is released from any future commitment with Jump Finance and will be refunded their tuition advance payments when ${universityInfo.name} is paid in full from the scholarship.
        <br><br>
        <strong>One time Jump Finance Application Fee</strong>
        <br>
        ${
            universityInfo.app_fee_flat === 0
              ? `Jump Finance one time application fee is ${universityInfo.app_fee_percentage * 100}% of first tuition advance.`
              : `Jump Finance one time application fee is ${universityInfo.app_fee_flat}.`
        }
        <br><br>
        <strong>Minimum Monthly Fees while studying</strong>
        <br>
        Minimum monthly tuition advance payment while studying: GMD ${universityInfo.during_studies_min_payment}
        <br>
        Jump Finance monthly membership fee: GMD ${universityInfo.during_studies_membership_fee}
        <br>
        Total monthly payment while studying: GMD ${universityInfo.during_studies_min_payment + universityInfo.during_studies_membership_fee}
        <br><br>
        <strong>Minimum Monthly Fees post graduation</strong>
        <br>
        If the student has not paid off the tuition advance in full after a six month grace period after graduating, the student’s monthly fees will increase, as broken down below:
        <br>
        Minimum monthly tuition advance payment post graduation: GMD ${universityInfo.after_studies_min_payment}
        <br>
        Jump Finance monthly membership fee: GMD ${universityInfo.after_studies_membership_fee}
        <br>
        Total monthly payment post graduation: GMD ${universityInfo.after_studies_min_payment + universityInfo.after_studies_membership_fee}
        <br><br>
        <strong>Ongoing Relationship</strong>
        <br>
        As part of their commitment to repayment, the student agrees to quarterly check-ins with the Jump Finance team. As part of their commitment to the student’s educational and career success, Jump Finance commits to providing ongoing mentorship in financial literacy, job training, and career building. If repayments are made in a timely and consistent manner, as determined by ${universityInfo.name} and Jump Finance, the student can reapply for additional tuition advances to complete their post-secondary studies. To do so, the student may need to go through an additional application process before receiving an additional tuition advance.
        <br><br>
        <strong>Execution of Agreement</strong>
        <br>
        The parties hereby agree to the above terms and that any changes must be completed through a mutually agreed upon written amendment to the above agreement.
    `
    if (userData === null || phoneNumber === '' || email === '' || Object.keys(universityInfo).length === 0) {
        // If any variable is still null, render the spinner
        return (
          <Box pt={"600px"}>
            <CustomSpinner />
          </Box>
        );
      }

        return (
          <div style={{ marginBottom: "96px" }}>
            <Navbar />
            <Box bg="#00D29D" w="100%" h="8vh" display="flex" alignItems="center" justifyContent="center">
              <Text fontSize="16px" fontWeight="semibold" mt="1vh">{userData.first_name}'s Account</Text>
            </Box>
            {userData && (
              <Box p="4" boxShadow="lg" borderRadius="2xl" bg="white" mt="8" borderWidth="1px" position="relative" mx="5">        
                <Flex alignItems="flex-start" flexDirection="column">
                  <Flex alignItems="center">
                    <Box pl="3">
                      <Text fontSize="4xl" fontWeight="bold">{userData.first_name} {userData.last_name}</Text>
                    </Box>
                  </Flex>
        
                  <Flex mt="4" p="4" borderWidth="1px" borderRadius="2xl" width="100%" position="relative">
                    {/* Existing basic information content */}
                    <Box>
                      <Text fontSize="xl" fontWeight="bold" mb="2">Basic Information</Text>
                      <Text fontSize="lg"><b>University:</b> {userData.university.name}</Text>
                      <Text fontSize="lg"><b>Mat Number:</b> {userData.mat_number}</Text>
                      <Text fontSize="lg"><b>Phone Number:</b> {phoneNumber}</Text>
                      <Text fontSize="lg"><b>Email:</b> {email}</Text>

                      <Button
                        borderRadius="xl"
                        colorScheme="gray"
                        size="sm"
                        mt="3"
                        onClick={onOpen}
                      >
                        Edit Info
                      </Button>
                    </Box>
                  </Flex>
        
                  <Accordion allowToggle mt="4" w="full">
                    <AccordionItem>
                      <h2>
                        <AccordionButton >
                          <Box flex="1" textAlign="left" fontSize="lg" fontWeight="bold" mb="2">
                            Contract Information
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text fontSize="lg" fontWeight="bold" mb="2">Start Date: {format(new Date(userData.start_date), 'MMMM dd, yyyy')}</Text>
                        <div dangerouslySetInnerHTML={{ __html: contractInfo }} />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Flex>
              </Box>
            )}
            
            <HStack mt="4" ml="8">
              <ExternalLinkIcon boxSize={5}/>
              <Button
                borderRadius="2xl"
                colorScheme="black"
                size="md"
                variant='link'
                onClick={logout}
              >
                Sign Out
              </Button>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Basic Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl id="phone-number" mb={4}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      value={modalPhoneNumber}
                      onChange={(e) => setModalPhoneNumber(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      value={modalEmail}
                      onChange={(e) => setModalEmail(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="green" mr={3} onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                  <Button colorScheme='gray' onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        );        
}

export default Account;
