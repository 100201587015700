import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          '::placeholder': {
            color: 'gray.400' // This sets the placeholder color to a faded gray
          }
        }
      }
    },
    Select: {
      baseStyle: {
        field: {
          '::placeholder': {
            color: 'gray.400'
          }
        }
      }
    }
  }
});

export default theme;