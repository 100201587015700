import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, Box, Text, Button, Flex, Divider, Alert, AlertIcon, AlertTitle, AlertDescription, Heading, SimpleGrid } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons'; // Import TimeIcon
import Navbar from '../Components/Navbar';
import CustomSpinner from '../Components/CustomSpinner';
import PaymentCard from '../Components/PaymentCard';
import { useAuth } from '../Components/auth/AuthProvider';

function Home() {
  const [depositedMinusExpected, setDepositedMinusExpected] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [showAlert, setShowAlert] = useState(true); // Set showAlert to true initially
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const config = useMemo(() => ({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }), [token]);

  const formattedDate = dueDate => {
    const date = new Date(dueDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchFinancialData = async () => {
      try {
        const financeResponse = await axios.get(`${baseUrl}/applications/${user}/finances`, config);
        const depositedMinusExpectedValue = financeResponse.data.deposited_minus_expected;
        setDepositedMinusExpected(depositedMinusExpectedValue);
        setShowAlert(depositedMinusExpectedValue < 0);
      } catch (error) {
        console.error("Failed to fetch financial data:", error);
        setDepositedMinusExpected('Error fetching financial data');
        setShowAlert(true); // Show alert on error
      }
    };

    const fetchInvoices = async () => {
      try {
        const invoiceResponse = await axios.get(`${baseUrl}/deposits?student_id=${user}`, config);
        const formattedInvoices = invoiceResponse.data.results.map(invoice => ({
          id: invoice.id,
          title: invoice.memo,
          dueDate: formattedDate(invoice.date),
          amount: invoice.amount,
        })).sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate)).slice(0, 2);
        setInvoices(formattedInvoices);
      } catch (error) {
        console.error("Failed to fetch invoices:", error);
      }
    };

    fetchFinancialData();
    fetchInvoices();
  }, [user, token, config]);

  if (depositedMinusExpected === null) {
    return (
        <Box pt={"600px"}>
            <CustomSpinner />
        </Box>
    )
}

  return (
    <Flex direction="column" minH="100vh">
      <Box bg="#00D29D" w="100%" h="8vh" display="flex" alignItems="center" justifyContent="center">
          <Text fontSize="16px" fontWeight="semibold" mt="1vh">Home</Text>
      </Box>
      <Navbar />
      <Box p={5} mt={4}>
        {/* Alert */}
        <Alert status="error" borderRadius="md" mb={4} hidden={!showAlert}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Alert!</AlertTitle>
            <AlertDescription display="block">
              You are behind on your payments
            </AlertDescription>
          </Box>
        </Alert>
        
        <Box textAlign="center">
          <Text fontSize="lg" fontWeight="bold">Amount Behind</Text>
          <Text fontSize="7xl" fontWeight="bold">
            {depositedMinusExpected !== null
              ? `D${depositedMinusExpected < 0 ? `${Math.abs(depositedMinusExpected)}` : 0}`
              : 'Loading...'}
          </Text>
        </Box>
        
        <Flex mt={4} style={{ margin: "0 auto" }} justifyContent="center" alignItems="center" gap="20px" maxW="6xl">
          {/* Modified button */}
          <Button bg="#00D29D" flexGrow={1} size="md" onClick={() => navigate('/payment')}>Payment History</Button>
        </Flex>

        <Divider my={5} />

        <Box mt={5} pb={24}>
          {/* Clock Icon */}
          <Flex align="center" justify="center">
            <TimeIcon boxSize={5} mr={1} mb={3}/>
            <Heading size="md" mb={3}>Recent Payments</Heading>
          </Flex>
          <SimpleGrid columns={1} spacing={4} justifyItems="center" width="full">
            {invoices.map((invoice) => (
              <PaymentCard
                key={invoice.id}
                title={invoice.title}
                dueDate={invoice.dueDate}
                amount={invoice.amount}
                transactionType="Deposit"
              />
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
