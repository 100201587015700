import { 
  VStack, 
  Text, 
  Button, 
  Flex, 
  Box, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';
import landing_bg from "../assets/landing_bg.jpeg";
import { Link } from 'react-router-dom';

const Landing = () => {
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const initialRef = useRef(null);
    const {formData, updateFormData} = useFormData();

    const handleChange = (e) => {
      const { name, value } = e.target;
      updateFormData('registerInfo', { ...formData.registerInfo, [name]: value });
  };

    const handleSubmit = (e) => {
      e.preventDefault();
      navigate('/login');
    };

    const navigateToSteps = () => {
      navigate('/info');
    }

    return (
        <Flex
        direction="column"
        align="center"
        justify="center"
        h="100vh"
        position="relative" 
        color="white"
        >
        
        <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            bgImage={landing_bg}
            bgSize="cover"
            bgPos="center"
            filter="brightness(50%)"
            zIndex="-1" 
        ></Box>

        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          bg="teal.300"
          opacity="0.20"
          zIndex="-1"
        />

        <VStack mb="2" md="5" mt={{ base: "5%"}} textAlign="center">
          <Text fontSize={{ base: "4xl", md: "6xl", lg: "7xl" }} fontWeight="bold" lineHeight="1">
            Welcome to
          </Text>
          <Text fontSize={{ base: "4xl", md: "6xl", lg: "7xl" }} fontWeight="bold" lineHeight="1">
            Jump Finance
          </Text>
        </VStack>

        <Box mx={{ base: "80px", md: "40px" }}>
          <Text textAlign="center" mt="6" mb="10" fontSize="lg">
            Before we welcome you to the Jump Finance portal, we ask that you sign up or login to your account.
          </Text>

          <Button color="white" textColor="black" borderRadius="3xl" mt="20" w="full" size="lg" onClick={handleSubmit} >
            Login
          </Button>
          <Link to="/signup">
            <Button variant='link' textColor="white" borderRadius="3xl" mt="5" w="full" size="lg" onClick={onOpen}>
              Sign Up
            </Button>
          </Link>
        </Box>

        <Modal
                isOpen={isOpen}
                onClose={onClose}
                initialFocusRef={initialRef}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create your account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                    <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input 
                                  placeholder="New email" 
                                  ref={initialRef}
                                  name="email"
                                  value={formData.registerInfo.email || ''} 
                                  onChange={handleChange}
                                  mb={4}
                                  focusBorderColor="teal.500" />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input 
                                  placeholder="New password" 
                                  type="password"
                                  name="password"
                                  value={formData.registerInfo.password || ''}
                                  onChange={handleChange} 
                                  focusBorderColor="teal.500"
                                  />
                            </FormControl>
                        </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={navigateToSteps}>
                            Sign Up
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
      </Flex>
    );
};


export default Landing;