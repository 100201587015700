import React, { useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Image,
  Input,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';
import axios from 'axios';
import upload_invoice from '../assets/upload_invoice.png';
import { useAuth } from "../Components/auth/AuthProvider";
import { useIdData } from './IdDataContext';

const HomeUploadInvoice = () => {
  const navigate = useNavigate();
  const { formData } = useFormData();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const { idData } = useIdData();
  const toast = useToast();
  const { user, token } = useAuth();

  const invoiceSuccess = async () => {
    if (!file) {
      toast({
        title: "Invoice must be uploaded.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newFormData = new FormData();
    newFormData.append('id', user);
    console.log("HUF id: ", idData)
    newFormData.append('file', file, fileName);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload_invoices`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast({
          title: "File uploaded successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate('/home-invoice-success');
      }
    } catch (error) {
      toast({
        title: "File upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) { // 50 mb
        setFileError("File size exceeds 50MB");
        setFileName(null);
        setFileURL(null); // Clear the file URL
      } else {
        setFileError(null);
        setFileName(file.name);
        setFile(file);
        setFileURL(URL.createObjectURL(file)); // Set the file URL
      }
    }
  };

  const returnToHome = () => {
    navigate('/home')
  };

  return (
    <Box overflowY="auto" p={4} maxW="420px" mx="auto" >
      <Box bg="rgb(19, 189, 149)" mb='15px' color="white" p={4} textAlign="center" rounded='md' position="sticky" top={0} zIndex={2}>
        <Text fontSize="md" fontWeight="bold" align='left'>UPLOAD TUITION INVOICE</Text>
        <Text fontSize="sm" align='left'>Accepts JPG, JPEG, PNG, PDF</Text>
      </Box>

      <VStack align="center" justify="center" w="full">
        <Text fontSize="xl" fontWeight="bold" textAlign='center' mt='10px'>
          Upload Tuition Invoice
        </Text>
        <Box position="relative" mt="30px" mb="30px">
          <Image
            src={fileURL ? fileURL : upload_invoice} // Now displays the uploaded image if available
            objectFit='contain'
            boxSize="300px"
          />
          <Input
            id="file-upload"
            type="file"
            accept="image/jpg,image/jpeg,image/png,application/pdf"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            opacity="0"
            cursor="pointer"
            onChange={handleFileChange}
          />
        </Box>
        {fileError ? (
          <Text color="red.500" mb={5}>{fileError}</Text>
        ) : (
          fileName && (
            <Box mb={5} textAlign="center">
              <Text fontSize="md">Attached File: {fileName}</Text>
            </Box>
          )
        )}
      </VStack>

      <Button
        w="full"
        bg="blackAlpha.900"
        color="white"
        _hover={{ bg: "blackAlpha.800" }}
        mt={10}
        onClick={invoiceSuccess}
      >
        Submit
      </Button>

      <Button
        w="full"
        variant="outline"
        onClick={returnToHome}
        borderColor="black.200"
        mt={4}
      >
        Save and Exit
      </Button>
    </Box>
  );
};

export default HomeUploadInvoice;
