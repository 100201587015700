import React, { useState } from 'react';
import { Box, Button, VStack, Text, Progress, Container, List, ListItem, ListIcon, RadioGroup, Radio, Stack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LearningCheckImage from '../assets/Timeimg.png';

const steps = [
  {
    title: 'About Jump Finance',
    header: 'HOW IT WORKS',
    contents: [
      <Text key="line1" fontSize="md" align="left" lineHeight="1.5">
        Jump Finance aims to help students break up large tuition payments through monthly repayment plans.
      </Text>,
      <Text key="line2" fontSize="md" align="left" lineHeight="1.5" fontWeight="bold">
        Jump Finance is NOT a scholarship.
      </Text>,
      <Text key="line3" fontSize="md" align="left" lineHeight="1.5">
        Students are expected to pay back all money borrowed in a timely manner.
      </Text>,
    ],
  },
  {
    title: 'How to Apply',
    header: '3 STEPS TO APPLY',
    contents: [
      <Box key="boldStatement" mb={4} spacing={10}>
        <Text key="step1" fontSize="md" align="left" lineHeight="2" fontWeight="bold">
          1. Submit this application form
        </Text>
        <Text key="step2" fontSize="md" align="left" lineHeight="2">
          2. Upload your univeristy invoice
        </Text>
        <Text key="step3" fontSize="md" align="left" lineHeight="2">
          3. Pay your application fee
        </Text>
      </Box>,
      <Text key="note" fontSize="md" align="left" lineHeight="1.5">
        You are only an official Jump Finance student once you pay the application fee.
      </Text>
    ],
  },
  {
    title: 'Payment Information',
    header: 'HOW TO PAY',
    contents: [],
  },
  {
    title: 'Ready to Apply?',
    contents: [
      <Text mt={-4}>Start by taking this quick learning check.</Text>,
      <Box key="learning-check" mt={4} textAlign="center" w="full">
        <Box mx="auto" w="full" ml="75">
          <img src={LearningCheckImage} alt="Learning Check" style={{ maxWidth: '100%' }} />
        </Box>
        <Box marginRight="55px"> 
          <Text fontFamily="Raleway" fontWeight="700" fontSize="16px" mt={2} textAlign="right">
            ESTIMATED TIME: 2 MINUTES
          </Text>
        </Box>
      </Box>,
    ],
  },
  {
    title: 'Learning Check',
    contents: ['Answer these questions:'],
    quiz: true,
  },

   // ... add more steps if needed
];

const quizQuestions = [
  {
    question: 'What does Jump Finance provide?',
    options: ['A scholarship for students', 'Monthly repayments to break up larger tuition payments', 'Tuition discount'],
    correctAnswer: 1,
    feedback: {
      correct: 'Correct! Jump Finance provides a way for students to break up large tuition payments.',
      incorrect: 'Try again. Jump Finance provides monthly repayments to break up larger tuition payments, not a scholarship or discount.',
    },
  },
  {
    question: 'When are monthly payments due?',
    options: ['1st of each month', '3rd of each month', '5th of each month'],
    correctAnswer: 2,
    feedback: {
      correct: 'Correct! Payments are due on the 5th of each month.',
      incorrect: 'Try again. Monthly payments are due on the 5th of each month and require payments that include your MAT or GC number.',
    },
  },

  // add more questions if needed
];

function PaymentInformation({ onContinue, onBack }) {
  return (
    <>
      <List spacing={2} pl={0} alignSelf="flex-start" styleType="none">
        <ListItem display="flex" alignItems="center">
          <ListIcon as={FaCircle} color="black" boxSize="4px" mr="2" />
          Payments are due on the <Text as="span" fontWeight="bold">&nbsp;5th of each month</Text>
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={FaCircle} color="black" boxSize="4px" mr="2" />
          Include MAT number or GC number each time you pay
        </ListItem>
      </List>

      <Text color="rgb(19, 189, 149)" fontWeight="700" fontSize="18px" mb ="-2">
        IF YOU NO LONGER NEED US:
      </Text>

      <List spacing={2} pl={0} alignSelf="flex-start" styleType="none">
        <ListItem>
          <Box as="span" display="flex" alignItems="flex-start">
            <ListIcon as={FaCircle} color="gray.500" boxSize="4px" mt="10px" />
            <Text as="span" fontSize="md" align="left">
              If you receive a scholarship that pays off past and future tuition payments, you are released from future commitment to Jump Finance and advance payments when your university is paid in full
            </Text>
          </Box>
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={FaCircle} color="gray.500" boxSize="4px" mr="2" />
          Application fees and membership fees are nonrefundable
        </ListItem>
      </List>

      <Button
        w="full"
        bg="blackAlpha.900"
        color="white"
        _hover={{ bg: "blackAlpha.800" }}
        mt={10}
        onClick={onContinue}
      >
        Continue
      </Button>

      <Button
        w="full"
        variant="outline"
        onClick={onBack}
        borderColor="black.200"
      >
        Previous Page
      </Button>
    </>
  );
}

function Quiz() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [feedback, setFeedback] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  
  const navigate = useNavigate();

  const currentQuestions = quizQuestions.slice(currentQuestionIndex, currentQuestionIndex + 2);

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: Number(value),
    }));
  };

  const handleAnswerCheck = () => {
    let allCorrect = true;
    const newFeedback = {};
    currentQuestions.forEach((question, index) => {
      const actualIndex = currentQuestionIndex + index;
      if (selectedAnswers[actualIndex] === question.correctAnswer) {
        newFeedback[actualIndex] = question.feedback.correct;
      } else {
        newFeedback[actualIndex] = question.feedback.incorrect;
        allCorrect = false;
      }
    });
    setFeedback(newFeedback);
    setIsAnswerCorrect(allCorrect);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 2 < quizQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 2);
      setSelectedAnswers({});
      setFeedback({});
      setIsAnswerCorrect(false);
    } else {
      navigate('/basic-information'); 
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 300);
    }
  };

  return (
    <>
      <Text color="rgb(19, 189, 149)" fontWeight="700" fontSize="16px" mb="-4">
        ANSWER THESE QUESTIONS:
      </Text>
      {currentQuestions.map((question, index) => {
        const actualIndex = currentQuestionIndex + index;
        return (
          <React.Fragment key={actualIndex}>
            <Text fontSize="xl" fontWeight="bold">{question.question}</Text>
            <RadioGroup onChange={(value) => handleAnswerChange(actualIndex, value)} value={selectedAnswers[actualIndex]}>
              <Stack direction="column">
                {question.options.map((option, idx) => (
                  <Radio key={idx} value={idx}>{option}</Radio>
                ))}
              </Stack>
            </RadioGroup>
            {feedback[actualIndex] && <Text color={feedback[actualIndex] === question.feedback.correct ? 'green' : 'red'}>{feedback[actualIndex]}</Text>}
          </React.Fragment>
        );
      })}
      {!isAnswerCorrect ? (
        <Button
          w="full"
          bg="blackAlpha.900"
          color="white"
          _hover={{ bg: "blackAlpha.800" }}
          onClick={handleAnswerCheck}
          isDisabled={Object.keys(selectedAnswers).length !== currentQuestions.length}
        >
          Check Answers
        </Button>
      ) : (
        <Button
          w="full"
          bg="blackAlpha.900"
          color="white"
          _hover={{ bg: "blackAlpha.800" }}
          onClick={handleNextQuestion}
        >
          Apply
        </Button>
      )}
    </>
  );
}

function NewAccount() {
  const [stepIndex, setStepIndex] = useState(0);
  const progressValue = (stepIndex + 1) / steps.length * 100;
  const navigate = useNavigate();

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0){
      setStepIndex(stepIndex - 1);
    }
  };

  const currentStep = steps[stepIndex];

  const renderStepContent = () => {
    if (currentStep.title === 'Learning Check') {
      return <Quiz/>;
    }

    if (currentStep.title === 'Payment Information') {
      return <PaymentInformation onContinue={goToNextStep} onBack={goToPreviousStep} />;
    }

    if (typeof currentStep.contents === 'function') {
      // If contents is a function, call it with navigate and return the elements
      return currentStep.contents(navigate).map((content, idx) => (
        <Box key={idx} mb={4}>
          {typeof content === 'string' ? <Text>{content}</Text> : content}
        </Box>
      ));
    }

    return (
      <>
        {currentStep.contents.map((content, idx) => (
          <Box key={idx} mb={4}>
            {typeof content === 'string' ? <Text fontSize="md" align="left">{content}</Text> : content}
          </Box>
        ))}
        <Button
          w="full"
          bg="blackAlpha.900"
          color="white"
          _hover={{ bg: "blackAlpha.800" }}
          mt={10}
          onClick={goToNextStep}
        >
          Continue
        </Button>
        {stepIndex > 0 && (<Button
            w="full"
            variant="outline"
            onClick={goToPreviousStep}
            borderColor="black.200"
          >
            Previous Page
          </Button>
        )}
      </>
    );
  };

  return (
    <Container centerContent py="12" maxW="lg">
      <VStack 
        spacing={5} 
        w="full" p={4} 
        alignItems="flex-start" 
        bg="white" 
        boxShadow="xl" 
        rounded="md">
        <Box w="full" bg="rgb(19, 189, 149)" p={2} rounded="md">
          <Text color="white" fontSize="sm">{stepIndex === steps.length - 1 ? 'APPLIED' : 'INFORMATION'}</Text>
          <Text color="white" fontSize="md">{currentStep.title}</Text>
        </Box>
        <Progress value={progressValue} size="sm" colorScheme="green" w="full" />
        <Text color="black" fontSize="2xl" fontWeight="bold">
          {currentStep.title}
        </Text>
        {currentStep.header && (
          <Text color="rgb(19, 189, 149)" fontWeight="700" fontSize="18px" mb="-3">
            {currentStep.header}
          </Text>
        )}
        {renderStepContent()}
      </VStack>
    </Container>
  );
}

export default NewAccount;