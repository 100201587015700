import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("user") || null);
  const [token, setToken] = useState(
    localStorage.getItem("jf_student_portal") || ""
  );

  const navigate = useNavigate();
  const loginAction = async (data, onError) => {
    const formData = new FormData();
    formData.append("username", data.phoneNumber);
    formData.append("password", data.password);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/student_login`, formData)
      .then(async (res) => {
        console.log(res.data);
        const { user: loggedInUser, access_token: loggedInToken } = res.data;
        setUser(loggedInUser);
        setToken(loggedInToken);
        localStorage.setItem("user", loggedInUser);
        localStorage.setItem("jf_student_portal", loggedInToken);

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/upload_invoices/exists`, 
        {
          params: {
            id: loggedInUser
          },
          headers: {
            Authorization: `Bearer ${loggedInToken}`
          }
        });
        console.log(response.data);
        if((response.data.status != "approved" && response.data.status != "pending") && !response.data.file_path)
        {
          navigate("/home-upload-invoice");
        }
        else
        {
          navigate("/home");
        }
      })
      .catch(onError);
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("jf_student_portal");
    navigate("../");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
