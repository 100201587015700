import React from 'react';
import { Box, VStack, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';
import { useIdData } from './IdDataContext';
import axios from 'axios';

const ReviewApplication = () => {
  const navigate = useNavigate();
  const { formData } = useFormData();
  const { updateIdData } = useIdData();

  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const flattenFormData = (formData) => {
    return {
        ...formData.registerInfo,
        ...formData.basicInfo,
        ...formData.academicInfo,
        ...formData.loanInfo
    };
  };

  //flattened data to be sent to endpoints
  const flatData = flattenFormData(formData);

  const editBasicInfo = () => {
    navigate('/basic-information');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const editAcademicInfo = () => {
    navigate('/academic-information');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const editLoanInfo = () => {
    navigate('/loan-information');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const navToDone = () => {
    navigate('/application-received');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const submitApplication = () => {
    // Map month names to their numerical representations
    // const monthToNumber = {
    //   'June': 6,
    //   'December': 12
    // };

    // Convert month name to its numerical representation
    // const month = monthToNumber[flatData.expectedGradMonth];
    // Construct graduation_date using the specified month, year, and day
    // const graduationDate = `${flatData.expectedGradYear}-` +(month < 10 ? `0` : '')+`${month}-01`;

    // delete flatData.expectedGradMonth;
    // delete flatData.expectedGradYear;

    const universityMap = {
      'University of The Gambia': 1,
      'Gambia College': 2,
      'Management Development Institute': 3,
      "UTG Master's": 4
    };

    const universityId = universityMap[flatData.currentSchool];

    flatData.university = universityId;
    
    // flatData.graduation_date = new Date(graduationDate).toISOString().split('T')[0];
    flatData.dateOfBirth = new Date(flatData.dateOfBirth).toISOString().split('T')[0];
    flatData.status = 'open';

    console.log(flatData);

    // Comment
    axios.post(`${baseUrl}/applications/submission`, flatData)
    .then(response => {
      console.log(response.data);
      console.log(response.data.id);
      updateIdData(response.data.id);
    })
    .catch(error => {
      console.error('Error:', error);
    });
    // Send payment reminder to student
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/messaging/pay_invoice_reminder`, {
      student_number: flatData.mobileNumber,
    })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <Box overflowY="auto" p={4} maxW="420px" mx="auto" >
        <Box bg="rgb(19, 189, 149)" mb='15px' color="white" p={4} textAlign="center" rounded='md' position="sticky" top={0} zIndex={2}>
          <Text fontSize="md" fontWeight="bold" align='left'>Application Review</Text>
          <Text fontSize="sm" align='left'>Review and Edit Your Information</Text>
        </Box>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">Basic Information</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <Text>Name: {formData.basicInfo.firstName} {formData.basicInfo.surname}</Text>
              <Text>Date of Birth: {formData.basicInfo.dateOfBirth}</Text>
              <Text>Mobile Number: {formData.registerInfo.mobileNumber}</Text>
              <Text>Gender: {formData.basicInfo.gender}</Text>
              <Text>Birth Village/Town/City: {formData.basicInfo.placeOfBirthCity}</Text>
              <Text>Birth Country: {formData.basicInfo.placeOfBirthCountry}</Text>
              <Text>Street Address: {formData.basicInfo.streetAddress}</Text>
              <Text>Residing City: {formData.basicInfo.residenceCity}</Text>
              <Text>Region: {formData.basicInfo.residenceRegion}</Text>
              <Text>Ethnicity: {formData.basicInfo.ethnicity}</Text>
              <Text>Email: {formData.basicInfo.email}</Text>
              <Text>How did you hear about us: {formData.basicInfo.hearAboutUs}</Text>
              <Button size="sm" colorScheme="teal" onClick={editBasicInfo} mt='10px'>Edit Basic Info</Button>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" mb='10px'>Academic Information</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text>Highest Education Completed: {formData.academicInfo.highestEducation}</Text>
              <Text>Current or Prospective School: {formData.academicInfo.currentSchool}</Text>
              <Text>Student Status: {formData.academicInfo.studentStatus}</Text>
              <Text>Student ID: {formData.academicInfo.ID}</Text>
              <Text>Major: {formData.academicInfo.major}</Text>
              <Text>Expected Graduation Month: {formData.academicInfo.expectedGradMonth}</Text>
              <Text>Expected Graduation Year: {formData.academicInfo.expectedGradYear}</Text>
              <Text>Expected Career: {formData.academicInfo.expectedCareer}</Text>
              <Text>Earnings Per Month: {formData.academicInfo.earningsPerMonth}</Text>
              <Text>Current Work Status: {formData.academicInfo.workStatus}</Text>
              <Text>Do you always earn the same amount: {formData.academicInfo.earnSameAmount}</Text>
              <Button size="sm" colorScheme="teal" onClick={editAcademicInfo}mt='10px'>Edit Academic Info</Button>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">Loan Information</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text>How will a Jump Tuition Advance help you achieve your goals for the future: {formData.loanInfo.loanQuestion}</Text>
              <Button size="sm" colorScheme="teal" onClick={editLoanInfo} mt='10px'>Edit Loan Info</Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Button mt={4} bg="rgb(19,189,149)" w="full" onClick={() => {navToDone(); submitApplication()}}>
          SUBMIT APPLICATION
        </Button>
    </Box>
  );
};

export default ReviewApplication;