import React from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Text,
    useToast,
    Progress,
    Textarea
  } from '@chakra-ui/react';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';

const LoanInformation = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const {formData, updateFormData} = useFormData();

    const handleChange = (e) => {
      const { name, value } = e.target;
      // Ensure updateFormData properly handles the update
      updateFormData('loanInfo', { ...formData.loanInfo, [name]: value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/review-application');
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
      };

      return (
        <Box overflowY="auto" p={4} maxW="420px" mx="auto">
      <Box bg="rgb(19, 189, 149)" color="white" p={4} textAlign="center" rounded='md'position="sticky" top={0} zIndex={2}>
      <Text fontSize="md" fontWeight="bold" align='left'>Application</Text>
      <Text fontSize="sm" align='left'>Loan Specifics</Text>
        </Box>
        <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                <FormControl isRequired mt={6}>
                    <FormLabel htmlFor="loanQuestion">
                    How will a Jump Tuition Advance help you achieve your goals for the future?
                    </FormLabel>
                    <Textarea
                        id="loanQuestion"
                        name="loanQuestion"
                        value={formData.loanInfo.loanQuestion || ''}
                        onChange={handleChange}
                        placeholder="Enter details here..."
                        size="sm" 
                        height="200px" 
                    />
                </FormControl>
                <Button colorScheme="green" width="full" type="submit">Review Application</Button>
        </VStack>
      </Box>
    )     
}

export default LoanInformation;

