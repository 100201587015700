import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    Image,
    IconButton,
    InputGroup,
    InputProps,
    InputRightElement,
    useDisclosure,
    useToast,
    Center,
    Flex,
    VStack,
  } from "@chakra-ui/react";
  import { ChevronLeftIcon } from '@chakra-ui/icons';
  import React, { useState, useEffect } from 'react';  
  import { Link } from 'react-router-dom';
  
  import logo from "../assets/jumpfinance_logo.jpg";
  import { useRef } from "react";
  import { HiEye, HiEyeOff } from "react-icons/hi";
  import { useForm } from "react-hook-form";
  import { useAuth } from "../Components/auth/AuthProvider";
  import { useFormData } from './FormDataContext';
  import { useNavigate } from 'react-router-dom';


  
  const SignUp = () => {
    const auth = useAuth();
    const { register, handleSubmit } = useForm();
    const { isOpen, onToggle } = useDisclosure();
    const toast = useToast();
    const inputRef = useRef(null);
  
    const navigate = useNavigate();

    const {formData, updateFormData} = useFormData();

    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const onClickReveal = () => {
      onToggle();
      if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
      }
    };

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
        updateFormData('registerInfo', { ...formData.registerInfo, 'mobileNumber': mobileNumber })
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        updateFormData('registerInfo', { ...formData.registerInfo, 'password': password })
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSignUp = () => {
      const mobileNumberRegex = /^\d{0,10}$/;

      if (mobileNumber === '' || !mobileNumberRegex.test(mobileNumber)) {
            toast({
                title: "Enter a Valid Phone Number",
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
        } else if (password === '') {
            toast({
                title: "Enter a Password",
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
        }
        else if (password !== confirmPassword) {
            toast({
                title: "Passwords Do Not Match",
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
        } else {
            updateFormData('registerInfo', 
              { ...formData.registerInfo, 
                'mobileNumber': mobileNumber,
                'password': password });
            navigate('/info');
        }
      }
  
    return (
      <Container>
        <Link to="/">
          <ChevronLeftIcon boxSize={6} mt={{ base: "10%" }} cursor="pointer"/>
        </Link>
  
        <Box mx={{ base: "30px"}} mt={{ base: "10%" }}>
          <Text fontSize={{ base: "2xl" }} fontWeight="bold" lineHeight="1" color="black" align="left">
            Hello! Register to get started.
          </Text>
        </Box>
  
          <Flex
            direction="column"
            align="center"
            justify="center"
            position="relative" 
            color="black"
            mx={{ base: "20px"}}
            mt="70"
          >
            <FormControl>
              <Input 
                placeholder='Enter your phone number'
                type="tel" {...register("mobileNumber")} 
                variant='filled'
                borderColor="gray"
                borderWidth="1px"
                borderRadius="lg"
                style={{ height: '50px' }}
                onChange={handleMobileNumberChange}
              />
            </FormControl>
            <FormControl mt="5">
              <InputGroup>
              <InputRightElement style={{ height: '50px' }}>
                <IconButton
                  variant="text"
                  aria-label={isOpen ? "Mask password" : "Reveal password"}
                  icon={isOpen ? <HiEyeOff /> : <HiEye />}
                  onClick={onClickReveal}
                />
              </InputRightElement>
                <Input
                  ref={inputRef}
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                //   {...register("password")}
                  placeholder='Password'
                  variant='filled'
                  borderColor="gray"
                  borderWidth="1px"
                  borderRadius="lg"
                  style={{ height: '50px' }}
                  onChange={handlePasswordChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl mt="5">
              <InputGroup>
              {/* <InputRightElement style={{ height: '50px' }}>
                <IconButton
                  variant="text"
                  aria-label={isOpen ? "Mask password" : "Reveal password"}
                  icon={isOpen ? <HiEyeOff /> : <HiEye />}
                  onClick={onClickReveal}
                />
              </InputRightElement> */}
                <Input
                  ref={inputRef}
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                //   {...register("password")}
                  placeholder='Confirm Password'
                  variant='filled'
                  borderColor="gray"
                  borderWidth="1px"
                  borderRadius="lg"
                  style={{ height: '50px' }}
                  onChange={handleConfirmPasswordChange}
                />
              </InputGroup>
            </FormControl>
          </Flex>
  
            <Flex
            direction="column"
            align="center"
            justify="center"
            position="relative" 
            color="black"
            mx={{ base: "20px"}}
            mt="50"
          >
            <Button
              // colorScheme="teal"
              bg="#00D29D"
              variant="solid"
              width="100%"
              borderRadius="20px"
              size="lg"
              color="white"
              type="submit"
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </Flex>

          <Box textAlign="center" py="5" style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: 'white' }}>
            <Text fontSize="sm">
            Alrerady have an account?{" "}
            <Link to="/login" style={{ color: "#00D29D" }}>
                Login Now
            </Link>
            </Text>
        </Box>
      </Container>
    );
  };
  
  export default SignUp;
  