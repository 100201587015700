import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function formatDate(inputDate) {
  const dateParts = inputDate.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthNames[parseInt(month, 10) - 1];

  return `${monthName} ${parseInt(day, 10)}, ${year}`;
}

const PaymentCard = ({ title, dueDate, amount, style, behindAmount, transactionType, balanceAmount }) => {
  return (
    <Box
      borderRadius="xl"
      overflow="auto"
      p="3"
      boxShadow="md"
      style={style}
      bg="rgba(19, 189, 149, 0.18)"
      maxW="6xl"
      width="full"
      border="1px solid #E2E8F0" // Added thin black border
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text fontSize="sm" fontWeight="medium">{title.slice(0, 30)}</Text>
            {amount && (
              <Text fontSize="sm" fontWeight="semibold">
                {transactionType === 'Invoice' ? '+' : '-'}D{amount}
              </Text>
            )}
            {transactionType === "History" && (
              <Text fontSize="sm" color="black" fontWeight="semibold" textAlign="left">Behind: D{behindAmount}</Text>
            )}
          </Box>
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text fontSize="sm" color="black" textAlign="left" mt="3">
              {transactionType === "Invoice" ? `Invoiced: ${formatDate(dueDate)}` : transactionType === "History" ? `Due: ${formatDate(dueDate)}` : `Deposited: ${formatDate(dueDate)}`}
            </Text>
            {transactionType === "History" && (
                <Text fontSize="sm" color="black" fontWeight="semibold" textAlign="left">
                    Remaining Balance: {balanceAmount < 0 ? `(D${Math.abs(balanceAmount)})` : `D${balanceAmount}`}
                </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentCard;
