import React, { Children } from 'react';
import Navbar from '../Components/Navbar';
import { Box, Text, Image, Flex, VStack, IconButton, Link} from '@chakra-ui/react'; // Import Heading


import Accordion from '../Components/Accordion';


import jpfQuestionImg from '../assets/jpfQuestionImg.png';
import fullTimeImg from '../assets/fullTimejobImg.png';
import needResourcesImg from '../assets/needResourcesImg.png';
import studyAbroadImg from '../assets/studyAbroadImg.png';


function Resources() {


 const resourceTabs = [
   {
     Title: "I have a Jump Finance Related Question",
     Image: jpfQuestionImg,
     children: (
       <>
         <Text>Jump Finance Bank Account Numbers:</Text>
         <br />
         <Text>Bank: Ecobank</Text>
         <Text>Account Name: Jump Finance</Text>
         <Text>Account #: 6240025074</Text>
         <br />
         <Text>Bank: GTBank</Text>
         <Text>Account Name: Jump Finance</Text>
         <Text>Account #: 201-135803-110</Text>
         <br />
         <Text>Read our FAQs: <Link href="https://apply.jump-finance.com/faq" isExternal textColor="blue" textDecoration="underline" fontWeight="bold">https://apply.jump-finance.com/faq</Link></Text>
         <br />
         <Text>Send us a WhatsApp: <Link href="https://wa.me/17372026552" isExternal textColor="blue" textDecoration="underline" fontWeight="bold">+1 737 202-6552</Link></Text>
         <br />
         <Text>Visit our office at: Sahel Knowledge Campus, MDI Road, Kanifing</Text>
       </>
     )
   },
   {
     Title: "I want to get a full time job in The Gambia",
     Image: fullTimeImg,
     children: (
       <>
       <Text>Make a professional resume: <Link href="https://resume.co/" isExternal textColor="blue" textDecoration="underline" fontWeight="bold">https://resume.co/</Link></Text>
       <Text>Gam Jobs: <Link href="https://gamjobs.com/" isExternal textColor="blue" textDecoration="underline" fontWeight="bold">https://gamjobs.com/</Link></Text>
       <Text>Youth Empowerment Project: <Link href='https://yep.gm/opportunities?category=job-offer' isExternal textColor="blue" textDecoration="underline" fontWeight="bold">YEP | Opportunities</Link></Text>
     </>
     )
   },
   {
     Title: "I want to make some money part time online!",
     Image: fullTimeImg,
     children: (
       <>
         <Text>Set up an account at Preply to tutor students online:</Text>
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://preply.com" isExternal>Teach online: Become an online tutor at Preply</Link></Text>
         <br />
         <Text>Best practices: <Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://loom.com" isExternal>loom.com</Link></Text>
         <br />
         <Text>Set up an account at Upwork to do freelance projects (data, design etc): <Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://www.upwork.com/signup" isExternal>www.upwork.com/signup</Link></Text>
         <br />
         <Text>Set up an account at Fiverr to tutor students online:</Text>
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" isExternal>Fiverr - Freelance Services Marketplace</Link></Text>
       </>
     )
   },
   {
     Title: "I have a small business and I need resources:",
     Image: needResourcesImg,
     children: (
       <>
         <Text>I have a product (jewelry / clothes / art) I want to sell online:</Text>
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://forms.gle/HmTYEYoTwXNyC6728" isExternal>https://forms.gle/HmTYEYoTwXNyC6728</Link></Text>
         <br />
         <Text>I have a business where I need an investment to grow (Design an intake form that can be quantified):</Text>
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://google.com" isExternal>Jump Finance Loans - Business Information (google.com)</Link></Text>
       </>
     )
   },
   {
     Title: "I am interested in studying/working abroad:",
     Image: studyAbroadImg,
     children: (
       <>
         <Text>Scholarships | ESSA: <Link  textColor="blue" textDecoration="underline" fontWeight="bold"  href="https://africanscholarshiphub.org" isExternal>africanscholarshiphub.org</Link></Text>
         <br />
         <Text><Link  textColor="blue" textDecoration="underline" fontWeight="bold"  href="https://www.opportunitiesforafricans.com/category/scholarships/" isExternal>https://www.opportunitiesforafricans.com/category/scholarships/</Link></Text>
       </>
     )
   },
   {
     Title: "I Need Academic Resources:",
     Image: studyAbroadImg,
     children: (
       <>
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://www.khanacademy.org" isExternal>Khan Academy</Link></Text>
         <br />
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://www.codecademy.com" isExternal>Codecademy</Link></Text>
         <br />
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://scholar.google.com" isExternal>Google Scholar</Link></Text>
         <br />
         <Text><Link textColor="blue" textDecoration="underline" fontWeight="bold" href="https://grow.google/certificates" isExternal>Google Certifications</Link></Text>
         <br />
         <Text>Excel Videos</Text>
       </>
     )
   },
 ]




 return (
   <div>
     <Box bg="#00D29D" w="100%" h="8vh" display="flex" alignItems="center" justifyContent="center">
         <Text fontSize="16px" fontWeight="semibold" mt="1vh">Resource Hub</Text>
     </Box>
     <Box p={[2, 4]} mx="auto" maxW="1200px" overflow="hidden">
       <Text fontWeight="semibold" fontSize="25px" textAlign="center">Resources</Text>
     </Box>
     <Box p={[2, 4]} mx="auto" maxW="1200px" overflow="hidden">
       <VStack spacing={4} w="full" mb="3">
         {resourceTabs.map((tab) => (
           <Accordion title={tab.Title} image={tab.Image} children={tab.children}/>
         ))}
       </VStack>
     </Box>
     <Box marginTop="50px">
       <Navbar/>
     </Box>
   </div>
 );
}


export default Resources;