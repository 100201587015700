import React, { useState } from 'react';
import {
    Box,
    VStack,
    HStack,
    FormControl,
    Input,
    Select,
    Button,
    Text,
    useToast,
    Progress,
    Divider
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';
import './styles.css';

const BasicInformation = () => {
    const navigate = useNavigate();
    const { formData, updateFormData } = useFormData();
    const toast = useToast();

    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
        updateDate(e.target.value, day, year);
      };
    
      const handleDayChange = (e) => {
        setDay(e.target.value);
        updateDate(month, e.target.value, year);
      };
    
      const handleYearChange = (e) => {
        setYear(e.target.value);
        updateDate(month, day, e.target.value);
      };
    
      const updateDate = (month, day, year) => {
        if (month && day && year) {
          const monthNumber = String(new Date(Date.parse(month + " 1, 2024")).getMonth() + 1).padStart(2, '0');
          const formattedDate = `${monthNumber}/${day}/${year}`;
          handleChange({ target: { name: 'dateOfBirth', value: formattedDate } });
        }
      };
    
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData('basicInfo', { ...formData.basicInfo, [name]: value });
    };

    const validateEmail = (email) => {
        // Simple email validation regex
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(formData.basicInfo.email)) {
            toast({
                title: "Invalid email address.",
                description: "Please enter a valid email address.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        navigate('/academic-information');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
    };

    const cities = [
        'Bakau', 'Banjul', 'Basse Santa Su', 'Brikama', 'Brufut',
        'Farafenni', 'Janjanbureh', 'Jufureh', 'Kalagi', 'Kanilai',
        'Kerewan', 'Kololi', 'Kuntaur', 'Lamin (North Bank Division)',
        'Lamin (Western Division)', 'Mansa Konko', 'Nema Kunku', 'Serekunda',
        'Soma', 'Sukuta', 'Tanji'
    ];

    const regions = [
        'Banjul', 'Kanifing Municipality', 'West Coast Region', 'Lower River Region',
        'North Bank Region', 'Central River Region', 'Upper River Region'
    ];

    const contacts = [
        'Friend', 'Through University of Gambia', 'Internet Search', 'Other'
    ];

    // Taken from: https://www.britannica.com/place/western-Africa
    const countries = [
        'Benin', 'Burkina Faso', 'Cameroon', 'Cabo Verde', 'Chad', "Côte d'Ivoire",
        'Equatorial Guinea', 'The Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau',
        'Liberia', 'Mali', 'Mauritania', 'Niger', 'Nigeria', 'Senegal', 'Sierra Leone',
        'Togo'
    ];

    return (
        <Box overflowY="auto" p={4} maxW="420px" mx="auto">
            <Box bg="rgb(19, 189, 149)" color="white" p={4} textAlign="center" rounded='md' position="sticky" top={0} zIndex={2}>
                <Text fontSize="md" fontWeight="bold" align='left'>Application</Text>
                <Text fontSize="sm" align='left'>Basic Information</Text>
            </Box>
            <Progress value={100} size="sm" colorScheme="black" w="full" />

            <VStack as="form" spacing={4} onSubmit={handleSubmit}>
                <Text>Details must match your official ID (National Identity Card, Passport, or Birth Certificate).</Text>

                <Text textAlign='left' fontWeight='bold'>Personal Information</Text>

                <FormControl isRequired>
                    <Input placeholder="First Name" name="firstName" value={formData.basicInfo.firstName || ''} onChange={handleChange} _placeholder={{ color: 'black' }} />
                </FormControl>

                <FormControl isRequired>
                    <Input placeholder="Surname" name="surname" value={formData.basicInfo.surname || ''} onChange={handleChange} _placeholder={{ color: 'black' }} />
                </FormControl>

                <FormControl isRequired>
                    <VStack align="start" spacing={2}>
                        <Text>Birthday</Text>
                        <HStack spacing={2}>
                        <Select placeholder="Month" value={month} onChange={handleMonthChange} width="43%">
                            {months.map((m, index) => (
                            <option key={index} value={m}>
                                {m}
                            </option>
                            ))}
                        </Select>
                        <Select placeholder="Day" value={day} onChange={handleDayChange} width="26%">
                            {[...Array(31).keys()].map((d) => (
                            <option key={d + 1} value={String(d + 1).padStart(2, '0')}>
                                {String(d + 1).padStart(2, '0')}
                            </option>
                            ))}
                        </Select>
                        <Select placeholder="Year" value={year} onChange={handleYearChange} width="28%">
                            {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((y) => (
                            <option key={y} value={y}>
                                {y}
                            </option>
                            ))}
                        </Select>
                        </HStack>
                    </VStack>
                </FormControl>

                <FormControl isRequired>
                    <Select
                        placeholder="Select gender"
                        name="gender"
                        value={formData.basicInfo.gender || ''}
                        onChange={handleChange}
                    >
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="other">Other</option>
                    </Select>
                </FormControl>
                <Text fontSize='sm' mt='-15px'>This will not be used to determine tuition advance eligibility.</Text>
                <Divider borderColor='black' />

                <Text textAlign='left' fontWeight='bold'>Place of Birth</Text>

                <FormControl isRequired>
                    <Select placeholder="Village/Town/City" name="placeOfBirthCity" value={formData.basicInfo.placeOfBirthCity || ''} onChange={handleChange}>
                        {cities.map((city) => (
                            <option value={city} key={city}>{city}</option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl isRequired>
                    <Select
                        placeholder="Country" name="placeOfBirthCountry" value={formData.basicInfo.placeOfBirthCountry || ''} onChange={handleChange}>
                        {countries.map((country) => (
                            <option value={country} key={country}>{country}</option>
                        ))}
                    </Select>
                </FormControl>

                <Divider borderColor='black' />

                <Text textAlign="left" fontWeight='bold'>Place of Residence</Text>

                <FormControl>
                    <Input placeholder="Street Address (optional)" name="streetAddress" value={formData.basicInfo.streetAddress || ''} onChange={handleChange} _placeholder={{ color: 'black' }} />
                </FormControl>

                <FormControl isRequired>
                    <Select placeholder="Village/Town/City" name="residenceCity" value={formData.basicInfo.residenceCity || ''} onChange={handleChange}>
                        {cities.map((city) => (
                            <option value={city} key={city}>{city}</option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl isRequired>
                    <Select placeholder="Region" name="residenceRegion" value={formData.basicInfo.residenceRegion || ''} onChange={handleChange}>
                        {regions.map((region) => (
                            <option value={region} key={region}>{region}</option>
                        ))}
                    </Select>
                </FormControl>

                <Divider borderColor='black' />
                <FormControl >
                    <Input placeholder="Ethnicity (optional)" name="ethnicity" value={formData.basicInfo.ethnicity || ''} onChange={handleChange} _placeholder={{ color: 'black' }} />
                </FormControl>
                <Text fontSize='sm' mt='-15px'>This will not be used to determine tuition advance eligibility.</Text>

                <Divider borderColor='black' />
                <Text>We will use the following information to contact you.</Text>
                <FormControl isRequired>
                    <Input placeholder="Email" name="email" value={formData.basicInfo.email || ''} onChange={handleChange} _placeholder={{ color: 'black' }} />
                </FormControl>

                <Divider borderColor='black' />
                <FormControl isRequired>
                    <Select placeholder="How did you hear about Jump Finance?" name="hearAboutUs" value={formData.basicInfo.hearAboutUs || ''} onChange={handleChange}>
                        {contacts.map((contact) => (
                            <option value={contact} key={contact}>{contact}</option>
                        ))}
                    </Select>
                </FormControl>

                <Button type="submit" colorScheme="blue" width="full">
                    Save and Continue
                </Button>
                <Text align='center'>
                    You can review and edit your application before submitting.
                </Text>
            </VStack>
        </Box>
    );
};
export default BasicInformation;