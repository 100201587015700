import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Image,
  IconButton,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useToast,
  Center,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from '@chakra-ui/icons';

import { Link } from 'react-router-dom';

import logo from "../assets/jumpfinance_logo.jpg";
import { useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useAuth } from "../Components/auth/AuthProvider";

const Login = () => {
  const auth = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const inputRef = useRef(null);

  const { logOut } = useAuth();
  
  const onSubmit = (data) => {
    if (!isValidPattern(data.phoneNumber)) {
      console.log('hello')
      toast({
        title: "Invalid Pattern",
        description: "Please enter a valid phone number consisting of digits only.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    auth.loginAction(data, (err) => {
      toast({
        title: "Failed to Login",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error logging in: ", err);
    });
  };

  const isValidPattern = (phoneNumber) => {
    const pattern = /^[0-9]+$/;
    return pattern.test(phoneNumber);
  };

const onClickReveal = () => {
  onToggle();
  if (inputRef.current) {
  inputRef.current.focus({ preventScroll: true });
  }
};

  return (
    <Container>
      <Link to="/">
        <ChevronLeftIcon boxSize={6} mt={{ base: "10%" }} cursor="pointer"/>
      </Link>

      <Box mx={{ base: "30px"}} mt={{ base: "10%" }}>
        <Text fontSize={{ base: "2xl" }} fontWeight="bold" lineHeight="1" color="black" align="left">
          Welcome back. Glad to see you again!
        </Text>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          position="relative" 
          color="black"
          mx={{ base: "20px"}} // Adjusted mx to match the left and right margins
          mt="70"
        >
          <FormControl>
            <Input 
              onKeyPress={(e) => {
                // Allow only numeric keys (0-9)
                if (isNaN(Number(e.key)) || e.key === " ") {
                  e.preventDefault();
                }
                console.log(e.key)
              }}
              placeholder='Enter your phone number'
              type="tel" {...register("phoneNumber", { pattern: /^[0-9]+$/ })} 
              variant='filled'
              borderColor="gray"
              borderWidth="1px"
              borderRadius="lg"
              style={{ height: '50px' }}
            />
          </FormControl>
          <FormControl mt="5">
            <InputGroup>
              <InputRightElement style={{ height: '50px' }}>
                <IconButton
                  variant="text"
                  aria-label={isOpen ? "Mask password" : "Reveal password"}
                  icon={isOpen ? <HiEyeOff /> : <HiEye />}
                  onClick={onClickReveal}
                />
              </InputRightElement>
              <Input
                ref={inputRef}
                type={isOpen ? "text" : "password"}
                autoComplete="current-password"
                required
                {...register("password")}
                placeholder='Password'
                variant='filled'
                borderColor="gray"
                borderWidth="1px"
                borderRadius="lg"
                style={{ height: '50px' }}
              />
            </InputGroup>
          </FormControl>
        </Flex>

        <Link to="/forgotpassword">
            <Text
              color="#00D29D"
              cursor="pointer"
              textAlign="left"
              ml={{ base: "20px" }}
              mt="5"
              fontSize="sm"
            >
              Forgot Password
            </Text>
          </Link>

          <Flex
          direction="column"
          align="center"
          justify="center"
          position="relative" 
          color="black"
          mx={{ base: "20px"}}
          mt="50"
        >
          <Button
            // colorScheme="teal"
            bg="#00D29D"
            variant="solid"
            width="100%"
            borderRadius="20px"
            size="lg"
            color="white"
            type="submit"
          >
            Login
          </Button>
        </Flex>
      </form>

      <Box textAlign="center" py="5" style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: 'white' }}>
        <Text fontSize="sm">
          Don't have an account?{" "}
          <Link to="/signup" style={{ color: "#00D29D" }}>
            Register Now
          </Link>
        </Text>
      </Box>
    </Container>
  );
};

export default Login;
