import React from 'react';
import {
  Box,
  VStack,
  Text,
  List,
  ListItem,
  Button,
  Image,
  Container,
  Input
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import check from '../icons/check.png';
import { useFormData } from './FormDataContext';
import uploaded_invoice from '../assets/uploaded_invoice.png'
import { useAuth } from "../Components/auth/AuthProvider";

const HomeInvoiceSuccess = () => {
  const navigate = useNavigate();
  const { formData } = useFormData();
  const auth = useAuth();

  const goToPrevious = () => {
    navigate('/review-application');
  }

  const returnToHome = () => {
    navigate('/home')
  }

  return (
    <Box overflowY="auto" p={4} maxW="420px" mx="auto" >
        <Box bg="rgb(19, 189, 149)" mb='15px' color="white" p={4} textAlign="center" rounded='md' position="sticky" top={0} zIndex={2}>
                <Text fontSize="md" fontWeight="bold" align='left'>APPLIED</Text>
                <Text fontSize="sm" align='left'>What's Next</Text>
        </Box>
        
        <VStack align="center" justify="center" w="full">
          <Text fontSize="xl" fontWeight="bold" textAlign='center' mt='10px'>
              Success!
          </Text>
          <Image
              src={uploaded_invoice}
              objectFit='contain'
              boxSize="200px"
              mt='30px'
              mb="30px"
          />
        </VStack>

        <Box bg="gray.200" rounded='md' w='full'>
          <VStack spacing={3} alignItems="flex-start" p={4}> {/* Added padding here */}
              <Text fontSize="lg" fontWeight="bold" color='rgb(19, 189, 149)'>
              NEXT STEPS:
              </Text>
              <Text>Once processed, we will send you your application fee within the next few days.</Text>
              {/* <Text>In the meantime, feel free to reference our ___________.</Text> */}
          </VStack>
        </Box>

      <Button
        w="full"
        bg="blackAlpha.900"
        color="white"
        _hover={{ bg: "blackAlpha.800" }}
        mt={10}
        onClick={returnToHome}
      >
        Return to Home
      </Button>
    </Box>
  );
};

export default HomeInvoiceSuccess;
