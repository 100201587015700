import './Pages/Login';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Account from './Pages/Account';
import Payment from './Pages/Payment';
import Resources from './Pages/Resources';
import Landing from './Pages/Landing';
import NewAccount from './Pages/NewAccount';
import ForgotPassword from './Pages/ForgotPassword';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthProvider from "./Components/auth/AuthProvider";
import PrivateRoute from "./Components/auth/PrivateRoute";
import Confirmation from './Pages/Confirmation';
import BasicInformation from './Pages/BasicInformation';
import AcademicForm from './Pages/AcademicForm';
import LoanInformation from './Pages/LoanInformation';
import ReviewApplication from './Pages/ReviewApplication';
import { FormDataProvider } from './Pages/FormDataContext';
import { IdDataProvider } from './Pages/IdDataContext';
import SignUp from './Pages/SignUp';
import theme from './Components/theme';

import UploadInvoice from './Pages/UploadInvoice'
import InvoiceSuccess from './Pages/InvoiceSuccess'
import HomeUploadInvoice from './Pages/HomeUploadInvoice';
import HomeInvoiceSuccess from './Pages/HomeInvoiceSuccess';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Router>
          <AuthProvider>
            <IdDataProvider>
              <Routes>
                <Route exact path="/" element={<FormDataProvider><Landing /> </FormDataProvider>} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/signup" element={<FormDataProvider><SignUp /></FormDataProvider>} />
                <Route path="/info" element={<FormDataProvider><NewAccount /></FormDataProvider>} />
                <Route path="/application-received" element={<FormDataProvider><Confirmation /></FormDataProvider>} />

                <Route path="/basic-information" element={<FormDataProvider><BasicInformation /></FormDataProvider>} />
                <Route path="/academic-information" element={<FormDataProvider><AcademicForm /></FormDataProvider>} />
                <Route path="/loan-information" element={<FormDataProvider><LoanInformation /></FormDataProvider>} />
                <Route path="/review-application" element={<FormDataProvider><ReviewApplication /></FormDataProvider>} />

                <Route path="/upload-invoice" element={<FormDataProvider><UploadInvoice /></FormDataProvider>} />
                <Route path="/invoice-success" element={<FormDataProvider><InvoiceSuccess /></FormDataProvider>} />

                <Route path="/home-upload-invoice" element={<FormDataProvider><HomeUploadInvoice /></FormDataProvider>} />
                <Route path="/home-invoice-success" element={<FormDataProvider><HomeInvoiceSuccess /></FormDataProvider>} />

                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<Home />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/account" element={<Account />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/payment" element={<Payment />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/resources" element={<Resources />} />
                </Route>
              </Routes>
            </IdDataProvider>
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </div>
  );
}

export default App;