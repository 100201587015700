import React, { useEffect, useState } from 'react';
import { Box, Text, Heading, SimpleGrid, Flex, Divider } from '@chakra-ui/react';
import PaymentCard from '../Components/PaymentCard';
import axios from 'axios';
import Navbar from '../Components/Navbar';
import CustomSpinner from '../Components/CustomSpinner';
import { useAuth } from '../Components/auth/AuthProvider';

const PaymentsPage = () => {
    const [upcomingPaymentsData, setUpcomingPaymentsData] = useState([]);
    const [paymentHistoryData, setPaymentHistoryData] = useState(null);
    const [isBehindOnPayments, setIsBehindOnPayments] = useState(false);
    const [behindAmount, setBehindAmount] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const { user, token } = useAuth();

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BACKEND_URL;

        const fetchInvoices = async () => {
            try {
                const response = await axios.get(`${baseUrl}/invoices?student_id=${user}`, config);
                const formattedUpcomingPayments = response.data.results.map(invoice => ({
                    id: invoice.id,
                    title: invoice.memo,
                    dueDate: formattedDate(invoice.date),
                    amount: invoice.amount,
                })).sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
                setUpcomingPaymentsData(formattedUpcomingPayments);
            } catch (error) {
                console.error("Failed to fetch invoices:", error);
            }
        };

        const fetchPaymentHistory = async () => {
            try {
                const response = await axios.get(`${baseUrl}/deposits?student_id=${user}`, config);
                const formattedData = response.data.results.map(deposit => ({
                    id: deposit.id,
                    title: deposit.memo,
                    dueDate: formattedDate(deposit.date),
                    amount: deposit.amount,
                })).sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
                setPaymentHistoryData(formattedData);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        const fetchFinanceData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/applications/${user}/finances`, config);
                const { deposited_minus_expected } = response.data;
                const { balance } = response.data;
                setIsBehindOnPayments(deposited_minus_expected < 0);
                setBehindAmount(Math.abs(deposited_minus_expected));
                setBalanceAmount(balance);
            } catch (error) {
                console.error("Failed to fetch finance data:", error);
            }
        };

        fetchInvoices();
        fetchPaymentHistory();
        fetchFinanceData();
    }, [user]);

    const formattedDate = dueDate => {
        const date = new Date(dueDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getNextMonthDate = () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        nextMonth.setDate(5); // Set day to 5th
        return nextMonth.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    };

    const combinedData = () => {
        let data = [...upcomingPaymentsData, ...paymentHistoryData];
        data.sort((b, a) => new Date(a.dueDate) - new Date(b.dueDate));
        return data;
    };

    if (paymentHistoryData === null) {
        return (
            <Box pt={"600px"}>
                <CustomSpinner />
            </Box>
        )
    }

    return (
        <>
            <Box bg="#00D29D" w="100%" h="8vh" display="flex" alignItems="center" justifyContent="center">
                <Text fontSize="16px" fontWeight="semibold" mt="1vh">Payment</Text>
            </Box>

            <Box position="fixed" top="calc(8vh)" width="full" zIndex="3">
                <Navbar />
                <Flex justifyContent="center" alignItems="center" bg="white" p={4}>
                    <Box width="full" maxW="6xl">
                        <Heading size="md" mb={4} textAlign="left" style={{ color: 'black' }}>Upcoming Payments</Heading>
                        {upcomingPaymentsData.length > 0 && (
                            <PaymentCard
                                title={"Jump Finance Dues"}
                                dueDate={getNextMonthDate()}
                                style={
                                    isBehindOnPayments
                                        ? { backgroundColor: '#FFD7D7', color: 'black' }
                                        : { backgroundColor: "rgba(19, 189, 149, 0.18)", color: 'black' }
                                }
                                behindAmount={isBehindOnPayments ? behindAmount : 0}
                                balanceAmount={balanceAmount}
                                transactionType={"History"}
                            />
                        )}
                    </Box>
                </Flex>
            </Box>

            <Box position="fixed" top="calc(8vh + 110px + 64px)" width="full" zIndex="2" bg="white">
                <Flex justifyContent="center">
                    <Divider maxW="6xl" borderWidth="1px" borderColor="#E2E2E2" />
                </Flex>
            </Box>

            <Box position="fixed" top="calc(8vh + 110px + 64px)" width="full" zIndex="1">
                <Flex justifyContent="center" alignItems="center" bg="white" p={4}>
                    <Box width="full" maxW="6xl">
                        <Heading size="md" textAlign="left" style={{ color: 'black' }}>Payment History</Heading>
                    </Box>
                </Flex>
            </Box>


            <Box mt="calc(8vh + 100px + 64px)" overflowY="auto" height="calc(100vh - (8vh + 200px + 64px))" width="full" style={{ color: 'black' }} pb="24">
                <Flex direction="column" alignItems="center" p={4}>
                    <SimpleGrid columns={1} spacing={4} justifyItems="center" width="full" maxW="6xl" css={{ overflow: 'hidden' }}>
                        {combinedData().map((payment) => (
                            <PaymentCard
                                key={payment.id}
                                title={payment.title}
                                dueDate={payment.dueDate}
                                amount={payment.amount}
                                transactionType={upcomingPaymentsData.find(upcomingPayment => upcomingPayment.id === payment.id) ? "Invoice" : "Deposit"}
                                style={{ color: 'black' }}
                            />
                        ))}
                    </SimpleGrid>
                </Flex>
            </Box>

        </>
    );
};

export default PaymentsPage;
