import React, { createContext, useContext, useState } from 'react';

const FormDataContext = createContext();

export const useFormData = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        registerInfo: {
            mobileNumber: '',
            password: '',
        },
        basicInfo: {
            firstName: '',
            surname: '',
            dateOfBirth: '',
            gender: '',
            placeOfBirthCity: '',
            placeOfBirthCountry: '',
            streetAddress: '',
            residenceCity: '',
            residenceRegion: '',
            ethnicity: '',
            email: '',
            hearAboutUs: '',
        },
        academicInfo: {
            highestEducation: '',
            currentSchool: '',
            studentStatus: '',
            ID: '',
            major: '',
            GPA: '',
            expectedCareer: '',
            // expectedGradMonth: '',
            // expectedGradYear: '',
            earningsPerMonth: '',
            workStatus: '',
            earnSameAmount: ''
        },
        loanInfo: {loanQuestion: ''}
    });

    const updateFormData = (section, data) => {
        setFormData(prev => ({
            ...prev,
            [section]: { ...prev[section], ...data }
        }));
    };

    return (
        <FormDataContext.Provider value={{ formData, updateFormData }}>
            {children}
        </FormDataContext.Provider>
    );
};