import React from "react";
import { Flex, Spinner, Box } from "@chakra-ui/react";

const CustomSpinner = () => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"Center"}
      position={"absolute"}
      top={0}
    >
      <Spinner
        thickness="6px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xxl"
      />
    </Box>
  );
};

export default CustomSpinner;