import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Flex, Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { IoHome, IoWallet, IoBook, IoPerson } from 'react-icons/io5';

function Navbar() {
  const location = useLocation();

  return (
    <Flex
      bg="#00D29D"
      color="white"
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="full"
      position="fixed"
      left="0"
      right="0"
      bottom="0"
      zIndex="10"
      borderTop="2px solid grey"
    >
      <NavBox to="/home" icon={IoHome} label="Home" selected={location.pathname === '/home'} />
      <NavBox to="/payment" icon={IoWallet} label="Payment" selected={location.pathname === '/payment'} />
      <NavBox to="/resources" icon={IoBook} label="Resources" selected={location.pathname === '/resources'} />
      <NavBox to="/account" icon={IoPerson} label="Account" selected={location.pathname === '/account'} />
    </Flex>
  );
}

const NavBox = ({ to, icon, label, selected }) => {
  const iconColor = useColorModeValue(selected ? "#00D29D" : "black", selected ? "#00D29D" : "white");
  const textColor = useColorModeValue(selected ? "#00D29D" : "black", selected ? "#00D29D" : "white");

  return (
    <Box
      as={Link}
      to={to}
      textAlign="center"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexBasis="25%"
      _hover={{ bg: "#555" }}
      p="10px"
      bg={"white"}
      color={textColor}
    >
      <Icon as={icon} w={3} h={3} color={iconColor} />
      <Text mt="1" fontSize="xs" color={textColor} p="2px">{label}</Text>
    </Box>
  );
}

export default Navbar;
