import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [phone, setPhone] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
  
    const sendVeification = async () => {
      // Verify that the phone number exists in the database
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/verify_phone`, {
        headers: {
          phone: phone
        }
      })
      .then(response => {
        if (response.data === true) {
          setIsSubmitted(true);
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/generate_forgot_password_entry`, {
            phone_number: phone,
            verification_code: Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000,
            created_at: new Date().toISOString()
          } )
          .then(response => {
            // send message to user with verification code
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/messaging/forgot_password`, {
              student_number: phone,
              one_time_code: response.data.verification_code
            })
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.error('Error:', error);
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
        } else {
          toast({
            title: "Phone Number Not In Our Records",
            position: "top",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }          
      })
      .catch(error => {
        console.error('Error:', error);
      });
    };

    const resetPassword = async () => {
      // check verifivation code and if correct, update student login page
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/verify_code`, {
        headers: {
          phone: phone,
          code: verificationCode
        }
      })
      .then(response => {
        if (response.data === true) {
          // check if passwords match
          if (newPassword === confirmNewPassword) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/update_password`, null, {
              headers: {
                phone: phone,
                newPassword: newPassword
              }
            })
            .then(response => {
                toast({
                  title: "Password Has Been Reset",
                  position: "top",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                setVerificationCode('');
                setNewPassword('');
                setConfirmNewPassword('');
                navigate("/")
            })
            .catch(error => {
                console.error('Error:', error);
            });
          } else {
            toast({
              title: "Passwords Do Not Match",
              position: "top",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: "Incorrect Verification Code",
            position: "top",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  
    return (
      <div>
        <Box bg="white" minH="100vh" display="flex" justifyContent="center" alignItems="center">
          <Container maxW="lg" py="24" px="8">
            <Box bg="white" p="10" boxShadow="md" borderRadius="xl">
              <Heading size="md" textAlign="center" mb={4}>
                Forgot Password
              </Heading>
              {!isSubmitted ? (
                <>
                  <Text mb={4} textAlign="center">
                    Enter your phone number to reset your password.
                  </Text>
                  <Stack spacing="4">
                    <FormControl>
                      <FormLabel htmlFor="phone">Phone</FormLabel>
                      <Input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      width="full"
                      color="white"
                      bg="rgba(84, 94, 99, 1)"
                      type="submit"
                      onClick={sendVeification}
                    >
                      Reset Password
                    </Button>
                  </Stack>
                </>
              ) : (
                <>
                  <Text mb={4} textAlign="center">
                    We've sent a verification code to your WhatsApp. Enter the code below with your new password.
                  </Text>
                  <Stack spacing="4">
                    <FormControl>
                      <FormLabel htmlFor="verificationCode">Verification Code</FormLabel>
                      <Input
                        type="number"
                        id="verificationCode"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="newPassword">New Password</FormLabel>
                      <Input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="confirmNewPassword">Confirm New Password</FormLabel>
                      <Input
                        type="password"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      width="full"
                      color="white"
                      bg="rgba(84, 94, 99, 1)"
                      type="submit"
                      onClick={resetPassword}
                    >
                      Verify and Reset Password
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Container>
        </Box>
      </div>
    );
  };
  
  export default ForgotPassword;
  