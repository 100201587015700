import React from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Text,
    useToast,
    Progress
  } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormData } from './FormDataContext';

const educations = [
  'High School', 'Vocational Training', 'Undergraduate First Year Complete',
  'Undergraduate Second Year Complete', 'Undergraduate Third Year Complete', 
  'Undergraduate Complete'
]

const majors = [
'Accounting', 'Agriculture', 'Banking', 'Biology', 'Chemistry', 'Christian Theology',
'Computer Science', 'Development Studies', 'Economics', 'Education', 'English Language',
'Environment Sciences', 'Finance', 'French', 'History', "Human Resource Management", 'Law', 'Information Systems', 
'Islamic Studies', 'Journalism and Digital Media', 'Engineering and Architecture',
'Mathematics', 'Medicine', 'Nursing', 'Physics', 'Political Science', 
'Public Administration','Public Health'
]


const careers= [
"Agricultural Engineer", "Agronomist", "Animal Scientist", "Archaeological/Paleontological Specialist",
"Biochemist", "Botanical Scientist", "Environmentalist", "Ecological Scientist",
"Food Engineer", "Forest Engineer", "Geochemist", "Geologist",
"Livestock Specialist", "Marine Bioscientist", "Metallurgist/Metallurgy Engineer", "Mining Engineer",
"Meteorologist", "Water Management Engineer", "Water Resource Scientist", "Auditor",
"Banker", "Booking Agent", "Business Analyst", "Customer Service Manager",
"Financial Advisor", "Financial Analyst", "Loan Specialist", "Office Manager",
"Quality Assurance Specialist", "Receptionist", "Risk Specialist", "Sales Consultant",
"Sales Representative", "Stock Broker", "Telemarketer", "Advertising Manager",
"AV Specialist", "Content Provider", "Desktop Support Engineer", "Editor",
"Graphic Designer", "IT Specialist", "Journalist", "Mobile Network Development Specialist",
"Producer", "Programmer", "Radio Presenter", "Reporter",
"Sound Engineer", "Telecommunication Specialist", "TV Presenter", "Aerospace Engineer",
"Air Quality Engineer", "Architect", "Automotive Engineer", "Biochemical Engineer",
"Biomedical Engineer", "Chemical Engineer", "Civil Engineer", "Electrical Engineer",
"Environmental Engineer", "Hardware Engineer", "Industrial Engineer", "Manufacturing Engineer",
"Mechanical Engineer", "Nuclear Engineer", "Petroleum Engineer", "Power Engineer",
"Process Engineer", "Quality Control Engineers and Technicians", "Renewable Energy Engineer", "Safety, Health, Environment Engineer",
"Software Engineer", "Traffic Engineer", "Web Designer", "Water Engineer",
"Caregiver", "Clinical Research Associate", "Dental Assistant", "Dentist",
"Medical Assistant", "Doctor/Physician", "Health Care Assistant", "Midwife",
"Nurse", "Nutritionist", "Pharmacist", "Psychiatrist",
"Psychologist", "Social Counselor", "Speech Therapist", "Surgeon",
"Veterinarian", "Assistant Teacher", "Fire Officer", "Guidance Counselor",
"Human Resources", "Inspector", "Laboratory Technician", "Law Enforcement Officer",
"Lawyer", "Legal Advisor", "Professor", "School Principal",
"Teacher", "University Teaching Assistant", "Economist"
];

const AcademicForm = () => {
  const navigate = useNavigate();
  const { formData, updateFormData } = useFormData();

  const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'GPA' || name === 'ID' || name === 'earningsPerMonth') {
        const regex = /^\d*(\.\d{0,2})?$/;

        if (regex.test(value)) {
          updateFormData('academicInfo', { ...formData.academicInfo, [name]: value });
        }
      } else {
        updateFormData('academicInfo', { ...formData.academicInfo, [name]: value });
      }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Navigate to the next page
      navigate('/loan-information');
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 300);
  };

    return (
        <Box overflowY="auto" p={4} maxW="420px" mx="auto">

        <Box bg="rgb(19, 189, 149)" color="white" p={4} textAlign="center" rounded='md'position="sticky" top={0} zIndex={2}>
      <Text fontSize="md" fontWeight="bold" align='left'>Application</Text>
      <Text fontSize="sm" align='left'>Academic and Work Information</Text>
        </Box>
        <Progress value='100%' size="sm" colorScheme="black" w="full"/>
          <VStack as="form" spacing={4} onSubmit={handleSubmit}>

            <FormControl isRequired>
              <Select 
                placeholder="Highest Education Completed" 
                name="highestEducation"
                value={formData.academicInfo.highestEducation || ''} 
                onChange={handleChange}>
                {educations.map((edu) => (
                  <option value={edu} key={edu}>{edu}</option>
                ))}
              </Select>
            </FormControl>
    
            <FormControl isRequired>
            <Select 
              placeholder="Current or Prospective School"
              name="currentSchool" 
              value={formData.academicInfo.currentSchool || ''}
              onChange={handleChange}>
                <option value="University of The Gambia">University of The Gambia</option>
                <option value="Gambia College">Gambia College</option>
                <option value="Management Development Institute">Management Development Institute</option>
                <option value="UTG Master's">UTG Master's</option>
              </Select>
            </FormControl>
    
            <FormControl isRequired>
            <Select 
              placeholder="Student Status" 
              name="studentStatus" 
              value={formData.academicInfo.studentStatus || ''}
              onChange={handleChange}>
                <option value="Full-time Student">Full-time Student</option>
                <option value="Part-time Student">Part-time Student</option>
                <option value="Not Enrolled">Not Enrolled</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <Input placeholder="Student ID (MAT Number)" name="ID" value={formData.academicInfo.ID || ''} onChange={handleChange} _placeholder={{ color: 'black' }}/>
            </FormControl>
    
            <FormControl isRequired>
              <Select 
                placeholder="Major" 
                name="major" 
                value={formData.academicInfo.major || ''}
                onChange={handleChange}>
                {majors.map((major) => (
                  <option value={major} key={major}>{major}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <Input placeholder="GPA" name="GPA" value={formData.academicInfo.GPA || ''} onChange={handleChange} _placeholder={{ color: 'black' }}/>
            </FormControl>
            <Text fontSize='x-small' mt='-10px'>If you do not yet have a GPA from UTG, please input your secondary education GPA.</Text>

            {/* <FormControl isRequired>
              <Select 
                placeholder="Expected Graduation Month" 
                name="expectedGradMonth"
                value={formData.academicInfo.expectedGradMonth || ''}
                onChange={handleChange}>
              <option value="June">June</option>
              <option value="December">December</option>
            </Select>
            </FormControl>

            <FormControl isRequired>
              <Select placeholder="Expected Graduation Year" name="expectedGradYear" value={formData.academicInfo.expectedGradYear || ''} onChange={handleChange}>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
            </Select>
            </FormControl> */}

            <FormControl isRequired>
              <Select 
                placeholder="Expected Career" 
                name="expectedCareer"
                value={formData.academicInfo.expectedCareer} 
                onChange={handleChange}>
                {careers.map((career) => (
                  <option value={career} key={career}>{career}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <Select 
                placeholder="Current Work Status" 
                name="workStatus"
                value={formData.academicInfo.workStatus || ''} 
                onChange={handleChange}>
              <option value="Working Full-Time">Working Full-Time</option>
              <option value="Do not currently have a job">Do not currently have a job</option>
              <option value="Working Part-Time">Working Part-Time</option>
              <option value="Other">Other</option>
            </Select>
            </FormControl>

            <FormControl isRequired>
              <Input 
                placeholder="Earnings Per Month" 
                name="earningsPerMonth" 
                value={formData.academicInfo.earningsPerMonth || ''}
                onChange={handleChange} 
                _placeholder={{ color: 'black' }}
                />
            </FormControl>

            <FormControl isRequired>
              <Select 
                placeholder="Do you always earn the same amount?" 
                name="earnSameAmount" 
                value={formData.academicInfo.earnSameAmount || ''}
                onChange={handleChange}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            </FormControl>


            <Button type="submit" colorScheme="blue" width="full">
              Save and Continue
            </Button>
            <Text align='center'>
                You can review and edit your application before submitting.
            </Text> 
          </VStack>
        </Box>
      );
};

export default AcademicForm;