import React from 'react';
import {
  Box,
  VStack,
  Text,
  List,
  ListItem,
  Button,
  Image,
  Container
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import check from '../icons/check.png';
import { useFormData } from './FormDataContext';
import { useAuth } from "../Components/auth/AuthProvider";

const Confirmation = () => {
  const navigate = useNavigate();
  const { formData } = useFormData();
  const auth = useAuth();

  const goToPrevious = () => {
    navigate('/review-application');
  }

  const returnToHome = () => {
    const loginData = {
      phoneNumber: formData.registerInfo.mobileNumber,
      password: formData.registerInfo.password
    };
    auth.loginAction(loginData)
  }

  const uploadInvoice = () => {
    navigate('/upload-invoice');
  }

  return (
    <Box overflowY="auto" p={4} maxW="420px" mx="auto">
        <Box bg="rgb(19, 189, 149)" mb='15px' color="white" p={4} textAlign="center" rounded='md' position="sticky" top={0} zIndex={2}>
                <Text fontSize="md" fontWeight="bold" align='left'>APPLIED</Text>
                <Text fontSize="sm" align='left'>What's Next</Text>
        </Box>
        
        <VStack align="center" justify="center" w="full">
        <Image
            src='https://media.istockphoto.com/id/1300874587/vector/group-of-young-people-characters-holding-wine-glasses-with-beverages-and-sparklers.jpg?s=612x612&w=0&k=20&c=KjmXL9g6fHmuk-VGPI2rD4ReS33RsGu2axyx57mPS8w='
            objectFit='contain'
            boxSize="300px"
            mt='-30px'
        />
        <Image src={check} objectFit='contain' boxSize='50px' mt='50px' mb='20px' />
        </VStack>

        <Box bg="gray.200" rounded='md' w='full'>
        <VStack spacing={3} alignItems="flex-start" p={4}> {/* Added padding here */}
            <Text fontSize="lg" fontWeight="bold" color='rgb(19, 189, 149)'>
            NEXT STEPS:
            </Text>
            <Text>1. Upload a photo of your university invoice</Text>
            <Text>2. Pay your application fee</Text>
        </VStack>
        </Box>

        <Button
        w="full"
        bg="blackAlpha.900"
        color="white"
        _hover={{ bg: "blackAlpha.800" }}
        mt={5}
        onClick={uploadInvoice}
        >
        Upload Invoice
        </Button>
        <Button
        w="full"
        bg="blackAlpha.900"
        color="white"
        _hover={{ bg: "blackAlpha.800" }}
        mt={3}
        onClick={returnToHome}
        >
        Return to Homepage
        </Button>
    </Box>
  );
};

export default Confirmation;