import React, { createContext, useContext, useState } from 'react';

const IdDataContext = createContext();

export const useIdData = () => useContext(IdDataContext);

export const IdDataProvider = ({ children }) => {
  const [idData, setIdData] = useState('');

  const updateIdData = (id) => {
    setIdData(id);
  };

  return (
    <IdDataContext.Provider value={{ idData, updateIdData }}>
      {children}
    </IdDataContext.Provider>
  );
};

export default IdDataContext;
