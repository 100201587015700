import React, { useState } from 'react';
import { IoChevronForward, IoChevronDown } from 'react-icons/io5';
import { Box, Text, Image, Flex, IconButton, Collapse } from '@chakra-ui/react';


const Accordion = ({ title, image, children }) => {
 const [open, setOpen] = useState(false);


 const handleToggle = () => setOpen(!open);


 return (
   <Box bg="rgba(19, 189, 149, 0.18)" p={3} borderRadius="md" w="full" cursor="pointer" onClick={handleToggle}>
     <Flex justifyContent="space-between" alignItems="center" h="full">
       <Box flex="3">
         <Text mb={2}>{title}</Text>
       </Box>
       <Box flex="1" borderRadius="md">
         <Image src={image} boxSize="100px" m="auto" display="block" />
       </Box>
       <IconButton
         variant="clear"
         aria-label="Toggle Accordion"
         icon={open ? <IoChevronDown /> : <IoChevronForward />}
       />
     </Flex>
     <Collapse in={open} animateOpacity>
       <Box mt={4} p={4} bg="white" borderRadius="md" shadow="md">
         {children}
       </Box>
     </Collapse>
   </Box>
 );
};


export default Accordion;